import "./App.css";
import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
} from "react-router-dom";
import Login from "./pages/login";
import store from "./Store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import logo from "./Rrjlogofinal.jpeg";
import { Navbar, Nav, Container, Button, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.min.css";
import Students from "./pages/students";
import Patients from "./pages/patients";
import PatientProfile from "./pages/patients/profile";
import NotFound from "./components/NotFound";

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router>
                    <div style={{ backgroundColor: "white", height: "100vh" }}>
                        <Navbar
                            bg="light"
                            variant="light"
                            expand="lg"
                            // fixed="top"
                            // style={{ margin: "5px" }}
                        >
                            <Container>
                                <Navbar.Brand href="/dashboard">
                                    <img
                                        alt=""
                                        src={logo}
                                        style={{ width: 160, height: 30 }}
                                        className="d-inline-block align-top"
                                    />
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto">
                                        <Nav.Link
                                            href="/"
                                            className="px-3 mx-2 menu-text"
                                        >
                                            Students
                                        </Nav.Link>
                                        {/* <NavDropdown
                                            title="Doctors"
                                            id="basic-nav-dropdown"
                                            className="px-3 mx-2 menu-text"
                                        >
                                            <NavDropdown.Item href="/doctors">
                                                All Doctors
                                            </NavDropdown.Item>
                                            <NavDropdown.Item href="/doctors">
                                                Doctor Requests
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                        <Nav.Link
                                            href="/patients"
                                            className="px-3 mx-2 menu-text"
                                        >
                                            Patients
                                        </Nav.Link> */}
                                    </Nav>
                                    {localStorage.getItem(
                                        "aspramedAdminJwtToken"
                                    ) ? (
                                        <Button
                                            onClick={() => {
                                                localStorage.removeItem(
                                                    "aspramedAdminJwtToken"
                                                );
                                            }}
                                            href="/login"
                                            variant="success"
                                            size="sm"
                                        >
                                            Logout
                                        </Button>
                                    ) : (
                                        <Button
                                            href="/login"
                                            size="sm"
                                            variant="success"
                                        >
                                            Login
                                        </Button>
                                    )}
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                        <Switch>
                            <Route path="/login">
                                <Login />
                            </Route>
                            <Route path="/patients">
                                <Patients />
                            </Route>
                            <Route path="/patient">
                                <PatientProfile />
                            </Route>
                            <Route path="/">
                                <Students />
                            </Route>
                            <Route>
                                <NotFound />
                            </Route>
                        </Switch>
                    </div>
                </Router>
            </Provider>
        );
    }
}

export default App;
