import React, { Component } from "react";
import { connect, useDispatch } from "react-redux";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Input, notification } from "antd";
// import { loginSuccess } from "~/store/auth/action";
import jwt_decode from "jwt-decode";
import { Button, Spinner } from "react-bootstrap";
import setAuthToken from "../../authToken";
import logo from "../../login.jpg";


class login extends Component {
    state = {
        isLoading: false,
        username: "",
        password: "",
    };
    componentDidMount() {
        if (
            typeof window !== "undefined" &&
            localStorage.getItem("aspramedAdminJwtToken")
        ) {
            window.location.href = "/";
        }
    }

    handleLoginSubmit = (e) => {
        Axios.post("/auth/adminLogin", e)
            .then((res) => {
                if (res.data.error) {
                    toast.error(res.data.error, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return;
                } else {
                    const { token } = res.data;
                    setAuthToken(token);
                    localStorage.setItem("aspramedAdminJwtToken", token);
                    const decoded = jwt_decode(token);
                    window.location = "/";
                    // this.props.dispatch(loginSuccess(decoded));
                    // Router.push("/");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("Something went wrong.", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            });
    };
    render() {
        return (
            <div className="main-container">
                <ToastContainer />
                <div className="content-container">
                    <div className="sub-container">
                        <Form
                            className="ps-form--account"
                            onFinish={this.handleLoginSubmit.bind(this)}
                        >
                            <div>
                                <div className="ps-form__content">
                                    <h4>Log In Your Account</h4>
                                    <p>Username</p>
                                    <Form.Item
                                        name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please input your username",
                                            },
                                        ]}
                                    >
                                        <Input
                                            className="form-control"
                                            type="name"
                                            placeholder="Username"
                                        />
                                    </Form.Item>
                                    <p>Password</p>
                                    <div className="form-forgot">
                                        <Form.Item
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please input your password",
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type="password"
                                                placeholder="Password..."
                                            />
                                        </Form.Item>
                                    </div>
                                    <div
                                        className="form-group submit"
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {this.state.isLoading ? (
                                            <Spinner
                                                type="grow"
                                                color="success"
                                                style={{
                                                    width: "3rem",
                                                    height: "3rem",
                                                }}
                                            />
                                        ) : (
                                            <Button
                                                type="submit"
                                                variant="success"
                                                style={{ width: "100%" }}
                                            >
                                                Login
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="image-container">
                    <img
                        alt=""
                        src={logo}
                        style={{ width: 400, height: 400 }}
                        className="d-inline-block align-top"
                    />
                </div>
            </div>
        );
    }
}

export default connect((state) => state)(login);
