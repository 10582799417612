import '../../App.css';
import React, { Component, useRef, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from 'react-router-dom';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { forwardRef } from 'react';
import Axios from 'axios';
import jwt_decode from 'jwt-decode';
import moment from 'moment-timezone';
import { Button } from 'react-bootstrap';
import { getTableSchema, dataTransformer } from './tableSchema';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const Students = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([{ name: 'Deepak' }, { name: 'Rahul' }]);

    const tableRef = useRef(null);

    // componentDidMount() {
    //     if (
    //         typeof window !== 'undefined' &&
    //         !localStorage.getItem('aspramedAdminJwtToken')
    //     ) {
    //         window.location.href = '/login';
    //     }
    //     let token = localStorage.getItem('aspramedAdminJwtToken');
    //     const decoded = jwt_decode(token);
    //     this.setState({
    //         isLoading: true
    //     });
    //     Axios.get('/management/getStudents?_start=0&_limit=500', {
    //         headers: {
    //             Authorization: token
    //         }
    //     })
    //         .then((res) => {
    //             if (res.data.error) {
    //                 this.setState({
    //                     isLoading: false
    //                 });
    //                 alert('Something went wrong. Please try again later.');
    //                 return;
    //             }
    //             this.setState({ data: res.data.data, isLoading: false });
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             this.setState({
    //                 isLoading: false
    //             });
    //             alert('Something went wrong. Please try again later.');
    //         });
    // }

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <div
                style={{
                    width: '95%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignSelf: 'center'
                }}
            >
                <MaterialTable
                    icons={tableIcons}
                    style={{ width: '100%', marginTop: '1em' }}
                    title=""
                    tableRef={tableRef}
                    isLoading={isLoading}
                    columns={getTableSchema()}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            let token = localStorage.getItem(
                                'aspramedAdminJwtToken'
                            );
                            setIsLoading(true);

                            Axios.get(
                                `/management/getStudents?_start=${query.page}&_limit=${query.pageSize}&title_contains=${query.search}`,
                                {
                                    headers: {
                                        Authorization: token
                                    }
                                }
                            )
                                .then((res) => {
                                    if (res.data.error) {
                                        setIsLoading(false);
                                        alert(
                                            'Something went wrong. Please try again later.'
                                        );
                                        return;
                                    }
                                    setIsLoading(false);
                                    tableRef.current.dataManager.changePageSize(
                                        query.pageSize
                                    );
                                    resolve({
                                        data: res.data.data,
                                        page: query.page,
                                        totalCount: res.data.totalCount
                                    });
                                })
                                .catch((err) => {
                                    console.log(err);
                                    setIsLoading(false);
                                    alert(
                                        'Something went wrong. Please try again later.'
                                    );
                                });
                        })
                    }
                    options={{
                        search: true,
                        pageSize: 200,
                        exportButton: true,
                        pageSizeOptions: [200, 500, 1000, 1500, 2000],
                        debounceInterval: 500,
                        emptyRowsWhenPaging: false
                    }}
                />
            </div>
        </div>
    );
};

export default Students;
