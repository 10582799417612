import * as actionTypes from "../Types";

const initialstate = {
  isAuthenticated: false,
  user: null,
};

export default function (state = initialstate, action) {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
