import React from "react";
import { BiError } from "react-icons/bi";
import "./../scss/404.scss";
import { Button } from "react-bootstrap";

const NotFound = () => {
    return (
        <div className="Not-Found-Container">
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <h1 style={{ textAlign: "center" }}>
                    Oops! we couldn't find that page
                </h1>
                <Button variant="success" href="/">
                    Go to home
                </Button>
            </div>
        </div>
    );
};

export default NotFound;
