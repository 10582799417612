import * as actionTypes from '../Types';

const initialstate = {
    isChinese: false
};

export default function (state = initialstate, action) {
    switch (action.type) {
        case actionTypes.CHANGE_LANGUAGE:
            return {
                ...state,
                isChinese: !state.isChinese
            };
        default:
            return state;
    }
}
