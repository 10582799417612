import React, { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";
import { Navbar, Nav, Container, Button, NavDropdown } from "react-bootstrap";

function PatientProfile(props) {
    return (
        <div><h1>HELLO WORLD</h1></div>
    );
}

export default PatientProfile;
