import "../../App.css";
import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
} from "react-router-dom";
import MaterialTable from "material-table";
import { withStyles } from "@material-ui/core/styles";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { forwardRef } from "react";
import Axios from "axios";
import jwt_decode from "jwt-decode";
import moment from "moment-timezone";
import { Button } from "react-bootstrap";
import {
    getTableSchema,
    dataTransformer,
  } from './tableSchema';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class patients extends Component {
    state = {
        data: [],
        isLoading: false,
    };

    data = [{name:"Deepak"}, {name:"Rahul"}]

    componentDidMount() {
        if (
            typeof window !== "undefined" &&
            !localStorage.getItem("aspramedAdminJwtToken")
        ) {
            window.location.href = "/login";
        }
        let token = localStorage.getItem("aspramedAdminJwtToken");
        const decoded = jwt_decode(token);
        this.setState({
            isLoading: true,
        });
        Axios.get("/admin-management/patients", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                if (res.data.error) {
                    this.setState({
                        isLoading: false,
                    });
                    alert("Something went wrong. Please try again later.");
                    return;
                }
                this.setState({ data: res.data.patients, isLoading: false });
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    isLoading: false,
                });
                alert("Something went wrong. Please try again later.");
            });
    }

    render() {
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        width: "95%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignSelf: "center",
                        // alignItems: "flex-end",
                    }}
                >
                    <MaterialTable
                        icons={tableIcons}
                        style={{ width: "100%", marginTop:'1em' }}
                        title=""
                        isLoading={this.state.isLoading}
                        columns={getTableSchema()}
                        data={this.state.data}
                        actions={
                            [
                                // {
                                //     icon: Edit,
                                //     tooltip: "Edit Data",
                                //     onClick: (_, rowData) => {
                                //         window.location.href = `/addProduct?id=${rowData._id}`;
                                //     },
                                // },
                                // {
                                //     icon: DeleteIcon,
                                //     tooltip: "Delete User",
                                //     onClick: (event, rowData) => {
                                //         if (
                                //             window.confirm(
                                //                 "You want to delete " + rowData.name
                                //             )
                                //         ) {
                                //             this.deleteProduct(rowData);
                                //         }
                                //     },
                                // },
                            ]
                        }
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    // let token = localStorage.getItem(
                                    //     "aspramedAdminJwtToken"
                                    // );
                                    // Axios.post(
                                    //     "/management/updateProduct",
                                    //     newData,
                                    //     {
                                    //         headers: {
                                    //             Authorization: token,
                                    //         },
                                    //     }
                                    // )
                                    //     .then((res) => {
                                    //         if (res.data.success) {
                                    //             let dataUpdate =
                                    //                 this.state.data;
                                    //             let finalData = dataUpdate.map(
                                    //                 (p) => {
                                    //                     if (
                                    //                         p._id.toString() ==
                                    //                         newData._id.toString()
                                    //                     ) {
                                    //                         return newData;
                                    //                     }
                                    //                     return p;
                                    //                 }
                                    //             );
                                    //             this.setState({
                                    //                 data: finalData,
                                    //             });
                                    //             resolve();
                                    //             return;
                                    //         }
                                    //         alert("something went wrong.");
                                    //         reject();
                                    //     })
                                    //     .catch((err) => {
                                    //         alert("something went wrong.");
                                    //         reject();
                                    //     });
                                }),
                        }}
                        options={{
                            // searchFieldStyle: {
                            //     fontSize: 13,
                            // },
                            // headerStyle: {
                            //     fontSize: 13,
                            // },
                            // cellStyle: {
                            //     fontSize: 13,
                            // },
                            search: true,
                            pageSize: 20,
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default patients;
