export const SET_USER = 'SET_USER';
export const LOGOUT = 'LOGOUT';
export const UI_START_LOADING = 'UI_START_LOADING';
export const UI_STOP_LOADING = 'UI_STOP_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const REMOVE_PASSWORD_SUCCESS = 'REMOVE_PASSWORD_SUCCESS';
export const START_CONSULTATION_LOADING = 'START_CONSULTATION_LOADING';
export const STOP_CONSULTATION_LOADING = 'STOP_CONSULTATION_LOADING';
export const SET_CONSULTATION_SUCCESS = 'SET_CONSULTATION_SUCCESS';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
