import React from 'react';
import Badge from 'react-bootstrap/Badge';

const getTableSchema = () => [
    // {
    //     title: "Profile Id",
    //     field: "profileId",
    //     initialEditValue: "initial edit value",
    //     render: (rowData) => (
    //         <p bold style={{ fontSize: 10 }}>
    //             {rowData.profileId}
    //         </p>
    //     ),
    // },
    {
        title: 'First Name',
        field: 'firstName',
        initialEditValue: 'initial edit value',
        render: (rowData) => (
            <p bold style={{ fontSize: 12 }}>
                {rowData.firstName}
            </p>
        )
    },
    {
        title: 'Last Name',
        field: 'lastName',
        initialEditValue: 'initial edit value',
        render: (rowData) => (
            <p bold style={{ fontSize: 12 }}>
                {rowData.lastName}
            </p>
        )
    },
    {
        title: 'Phone Number',
        field: 'phoneNumber',
        initialEditValue: 'initial edit value',
        render: (rowData) => (
            <p bold style={{ fontSize: 12 }}>
                {rowData.phoneNumber}
            </p>
        )
    },
    {
        title: 'Email',
        field: 'email',
        initialEditValue: 'initial edit value',
        render: (rowData) => (
            <p bold style={{ fontSize: 12 }}>
                {rowData.email}
            </p>
        )
    },
    {
        title: 'Address',
        field: 'address',
        initialEditValue: 'initial edit value',
        render: (rowData) => (
            <p bold style={{ fontSize: 12 }}>
                {rowData.address}
            </p>
        )
    },
    {
        title: 'City',
        field: 'city',
        initialEditValue: 'initial edit value',
        render: (rowData) => (
            <p bold style={{ fontSize: 12 }}>
                {rowData.city}
            </p>
        )
    },
    {
        title: 'State',
        field: 'state',
        initialEditValue: 'initial edit value',
        render: (rowData) => (
            <p bold style={{ fontSize: 12 }}>
                {rowData.state}
            </p>
        )
    },
    {
        title: 'Payment Done',
        field: 'paymentDone',
        initialEditValue: 'initial edit value',
        render: (rowData) => (
            <div
                style={{
                    backgroundColor:
                        rowData.paymentDone == 'Yes' ? '#1A7742' : '#D11E35',
                    display: 'flex',
                    padding: '2px',
                    justifyContent: 'center',
                    borderRadius: 5,
                    alignItems: 'center',
                    width: '100px'
                }}
            >
                <p
                    bold
                    style={{
                        fontSize: 12,
                        color: 'white',
                        margin: 0,
                        textAlign: 'center'
                    }}
                >
                    {rowData.paymentDone}
                </p>
            </div>
        )
    },
    {
        title: 'Course Name',
        field: 'courses',
        initialEditValue: 'initial edit value',
        render: (rowData) => (
            <p bold style={{ fontSize: 12 }}>
                {rowData.courses}
            </p>
        )
    },
    {
        title: 'Payments',
        field: 'paymentList',
        initialEditValue: 'initial edit value',
        render: (rowData) => (
            <p bold style={{ fontSize: 12 }}>
                {rowData.paymentList}
            </p>
        )
    },
    {
        title: 'payment Date',
        field: 'paymentDate',
        initialEditValue: 'initial edit value',
        render: (rowData) => (
            <p bold style={{ fontSize: 12 }}>
                {rowData.paymentDate}
            </p>
        )
    },
];

const dataTransformer = (data) => {
    return data.map((d) => {
        return { d };
    });
};

export { getTableSchema, dataTransformer };
