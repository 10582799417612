import React from "react";
import Badge from "react-bootstrap/Badge";

const getTableSchema = () => [
    {
        title: "Profile Id",
        field: "profileId",
        initialEditValue: "initial edit value",
        render: (rowData) => (
            <p bold style={{ fontSize: 10 }}>
                {rowData.profileId}
            </p>
        ),
    },
    {
        title: "Name",
        field: "name",
        initialEditValue: "initial edit value",
        render: (rowData) => (
            <a bold style={{ fontSize: 12 }} href={`/patient?id=${rowData._id}`}>
                {rowData.name}
            </a>
        ),
    },
    {
        title: "Timezone",
        field: "timezone",
        initialEditValue: "initial edit value",
        render: (rowData) => (
            <p bold style={{ fontSize: 12 }}>
                {rowData.timezone}
            </p>
        ),
    },
    {
        title: "Phone Number",
        field: "phoneNumber",
        initialEditValue: "initial edit value",
        render: (rowData) => (
            <p bold style={{ fontSize: 12 }}>
                {rowData.phoneNumber}
            </p>
        ),
    },
    {
        title: "Rating",
        field: "rating",
        initialEditValue: "initial edit value",
        render: (rowData) => (
            <div
                style={{
                    backgroundColor:
                        rowData.rating > 3.5
                            ? "#1A7742"
                            : rowData.rating > 2.5
                            ? "#FEB50D"
                            : "#D11E35",
                    display: "flex",
                    padding: "2px",
                    justifyContent: "center",
                    borderRadius: 5,
                    alignItems: "center",
                    width: "100px",
                }}
            >
                <p
                    bold
                    style={{
                        fontSize: 12,
                        color: "white",
                        margin: 0,
                        textAlign: "center",
                    }}
                >
                    {rowData.rating}
                </p>
            </div>
        ),
    },
    {
        title: "Gender",
        field: "gender",
        initialEditValue: "initial edit value",
        render: (rowData) => (
            <p bold style={{ fontSize: 12 }}>
                {rowData.gender}
            </p>
        ),
    },
    {
        title: "Total Appointments",
        field: "appointments",
        initialEditValue: "initial edit value",
        render: (rowData) => (
            <p bold style={{ fontSize: 12 }}>
                {rowData.appointments.length}
            </p>
        ),
    },
    {
        title: "Total Doctors",
        field: "doctors",
        initialEditValue: "initial edit value",
        render: (rowData) => (
            <p bold style={{ fontSize: 12 }}>
                {rowData.doctors.length}
            </p>
        ),
    },
];

const dataTransformer = (data) => {
    return data.map((d) => {
        return { d };
    });
};

export { getTableSchema, dataTransformer };
